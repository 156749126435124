/*
  file inputにjs_image-previewクラスを付加すると、
  変更があった際に
  - (file inputのid)-previewが設定されているimgのsrcに読み込まれたファイルを表示させます。
  - (file inputのid)-preview-placeholder-textが削除します。
*/

export default class ImagePreview {
  static prepare = (fileInput) => {
    fileInput.addEventListener('change', (event) => {
      if (fileInput.files && fileInput.files[0]) {
        var reader = new FileReader();
        reader.addEventListener('load', (event) => {
          var previewImageTag = document.getElementById(fileInput.id + '-preview');
          console.log(previewImageTag);
          previewImageTag.setAttribute('src', event.target.result);
          var previewImagePlaceholderText = document.getElementById(fileInput.id + '-preview-placeholder-text');
          if (previewImagePlaceholderText != null) {
            previewImagePlaceholderText.remove();
          }
        });
        reader.readAsDataURL(fileInput.files[0]);
      }
    });
  };
}

window.addEventListener('load', (event) => {
  fileInputs = document.querySelectorAll('.js_image-preview');
  fileInputs.forEach(function (fileInput) {
    ImagePreview.prepare(fileInput);
  });
});
