import * as timeago from 'timeago.js';
import timeago_ja from 'timeago.js/lib/lang/ja';

window.addEventListener('load', (event) => {
  timeago.register('ja', timeago_ja);
  const query = document.location.search;
  const locale = query.match(/lang=en/) ? 'en_US' : 'ja';
  const target_element = document.querySelectorAll('.timeago');
  if (target_element.length > 0) {
    timeago.render(document.querySelectorAll('.timeago'), locale);
  }
});
