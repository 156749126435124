import jQuery from "jquery";
import ImagePreview from "./image_preview";

$(window).on('load', function() {
  $('.js_cocoon-container').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    insertedItem.find('.js_image-preview').each(function (index, element) {
      ImagePreview.prepare(element);
    });
  });
});
