window.addEventListener('load', (event) => {
  fileInputs = document.querySelectorAll('.js_file-size-limit');
  fileInputs.forEach(function (fileInput) {
    var limit = 1024 * 1024 * 1; // FIXME: 1MB固定になっているので、データ属性などを使って指定できるようにしたい
    var errMessage = 'ファイルサイズを1MB以下にしてください。'; // FIXME: 国際化したい

    fileInput.addEventListener('change', (event) => {
      var files = fileInput.files
      if (files) {
        for(var i = 0; i < files.length; i++) {
          if(files[i].size > limit) {
            fileInput.value = '';
            alert(errMessage);
            event.stopImmediatePropagation();
            return;
          }
        }
      }
    });
  });
});
