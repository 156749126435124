export default class CheckboxToggler {
  static prepare = (toggler) => {
    toggler.toggle = () => {
      var targetQuery = toggler.dataset.toggleTarget;
      var targets = document.querySelectorAll(targetQuery);
      targets.forEach(function (target) {
        target.disabled = !toggler.checked;
      });
    };
    toggler.addEventListener('change', (event) => {
      toggler.toggle();
    });
    toggler.toggle();
  };
}

window.addEventListener('load', (event) => {
  var togglers = document.querySelectorAll('.js_status-toggler');
  togglers.forEach(function (toggler) {
    CheckboxToggler.prepare(toggler);
  });
});
